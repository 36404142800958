import React, { useCallback, useEffect, useState } from 'react';

import IRApi from '../api/larsIRApi';
import { FetchStatus } from '../constants/fetchStatus';
import { useAuthContext } from './AuthContextProvider';
import { getLogger } from '../utils/logger';
import { DimensionValue } from '../interfaces/logger';
import UnauthorizedAdminAccessError from '../errors/UnauthorizedAdminAccessError';
import { BadUserRequest } from '../errors';

interface IRUserArnsOnboardingState {
	userArns?: string;
	status: FetchStatus;
	error?: Error;
}
interface IRUserArnsOnboardingContextProps extends IRUserArnsOnboardingState {
	getStatus: () => void;
	setPostMethod: () => void;
	setGetMethod: () => void;
	updateUserArn: (arn: string) => void;
	setToPending: (state: boolean) => void;
	setToDiscard: (state: boolean) => void;
	setUserVibeId: (arn: any) => void;
	setCurrentArn: (arn: any) => void;
	vibeId: string;
	method: string;
	isDiscarded: boolean;
	isPending: boolean;
	currentArn: string;
}

const DEFAULT_STATE: IRUserArnsOnboardingState = {
	status: FetchStatus.Unspecified,
};

export const IRUserArnsOnboardingContext =
	React.createContext<IRUserArnsOnboardingContextProps | null>(null);

export const useIRUserArnsOnboardingContext =
	(): IRUserArnsOnboardingContextProps => {
		const contextState = React.useContext(IRUserArnsOnboardingContext);
		if (contextState === null) {
			throw new Error(
				'IRUserArnsOnboardingContext must be used within a IRUserArnsOnboardingProvider tag',
			);
		}
		return contextState;
	};

const isExpected = (error: Error): boolean => {
	return [UnauthorizedAdminAccessError, BadUserRequest].some(
		(e) => error instanceof e,
	);
};

export const IRUserArnsOnboardingContextProvider: React.FC<{
	children: React.ReactNode;
}> = ({ children }: { children: React.ReactNode }) => {
	const logger = getLogger();
	const { authState } = useAuthContext();
	const [userArnsOnboardingState, setUserArnsOnboardingState] =
		useState<IRUserArnsOnboardingState>(DEFAULT_STATE);
	const [method, setMethod] = useState('GET');
	const [newUserArn, setNewUserArn] = useState('');
	const [isDiscarded, setDiscardedToken] = useState(false);
	const [isPending, setPendingToken] = useState(false);
	const [vibeId, setVibeId] = useState('');
	const [currentArn, setCurrentUserArn] = useState('');

	const setUserVibeId = useCallback(
		(arn: string) => {
			setVibeId(arn);
		},
		[setVibeId],
	);

	const setCurrentArn = useCallback(
		(arn: string) => {
			setCurrentUserArn(arn);
		},
		[setCurrentUserArn],
	);

	const setPostMethod = useCallback(() => {
		setMethod('POST');
	}, [setMethod]);

	const setToDiscard = useCallback(
		(state: boolean) => {
			setDiscardedToken(state);
		},
		[setDiscardedToken],
	);

	const setToPending = useCallback(
		(state: boolean) => {
			setPendingToken(state);
		},
		[setPendingToken],
	);

	const setGetMethod = useCallback(() => {
		setMethod('GET');
	}, [setMethod]);

	const updateUserArn = useCallback(
		(arn: string) => {
			setNewUserArn(arn);
		},
		[setNewUserArn],
	);

	const getStatus = useCallback(async () => {
		if (authState.isAuthenticated && logger) {
			if (method === 'GET') {
				setUserArnsOnboardingState({
					status: FetchStatus.Loading,
				});
			} else {
				setUserArnsOnboardingState({
					status: isPending
						? FetchStatus.PendingApproval
						: isDiscarded
						? FetchStatus.Discarding
						: FetchStatus.Approving,
				});
			}

			IRApi.getStatus(
				method,
				newUserArn,
				currentArn,
				isPending,
				isDiscarded,
			)
				.then(({ result }) => {
					method === 'GET'
						? setUserArnsOnboardingState({
								status: FetchStatus.Loaded,
								userArns: JSON.stringify(result),
						  })
						: setUserArnsOnboardingState({
								status: FetchStatus.Loaded,
								userArns: '',
						  });
				})
				.catch((error) => {
					if (isExpected(error)) {
						error.status === 400
							? setUserArnsOnboardingState({
									status: FetchStatus.BadRequest,
									error: error,
							  })
							: setUserArnsOnboardingState({
									status: FetchStatus.ExpectedError,
									error: error,
							  });
					} else {
						logger.error(
							'Unexpected error captured in IRUserArnsOnboardingContextProvider',
							error,
						);
						logger.counterMetric({
							metricName: 'IRUserArnsOnboardingContextError',
							dimensionValue: DimensionValue.Error,
						});
						setUserArnsOnboardingState({
							status: FetchStatus.UnexpectedError,
							error: error,
						});
					}
				});
		}
	}, [
		currentArn,
		authState.isAuthenticated,
		isDiscarded,
		isPending,
		logger,
		method,
		newUserArn,
	]);

	useEffect(() => {
		getStatus();
	}, [getStatus]);

	return (
		<IRUserArnsOnboardingContext.Provider
			value={{
				...userArnsOnboardingState,
				getStatus: getStatus,
				setPostMethod,
				setGetMethod,
				updateUserArn,
				setToPending,
				setToDiscard,
				setUserVibeId,
				setCurrentArn,
				vibeId,
				method,
				isPending,
				isDiscarded,
				currentArn,
			}}
		>
			{children}
		</IRUserArnsOnboardingContext.Provider>
	);
};

export default IRUserArnsOnboardingContextProvider;
