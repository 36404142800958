/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Icon, Input } from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import { useNotificationContext } from '../../contexts/NotificationContextProvider';
import './InlineEdit.scss';
import { useIRUserArnsOnboardingContext } from '../../contexts/IRUserArnsOnboardingContextProvider';

type InlineEditProps = {
	value: string;
	onSave: (newValue: string) => void; // Callback to save the new value
};

const validateUserArn = (arn: string): boolean => {
	const arnRegex =
		/^arn:(aws|aws-cn|aws-us-gov):iam::\d{12}:role\/[\w+=,.@-]+$/; //Regex Pattern used to validate the User Arn
	return arnRegex.test(arn);
};

const InlineEdit = ({ value, onSave }: InlineEditProps) => {
	const [isEditing, setIsEditing] = useState(false);
	const [inputValue, setInputValue] = useState(value);
	const { makeInvisible, makeVisible, setMessage, setStatus, arnStatus } =
		useNotificationContext();
	const { setPostMethod, updateUserArn, setToPending, setToDiscard } =
		useIRUserArnsOnboardingContext();
	const isDefaultValue = !value;

	const handleEdit = () => {
		if (
			arnStatus === 'Approving' ||
			arnStatus === 'Discarding' ||
			arnStatus === 'Loading' ||
			arnStatus === 'Pending Approval'
		) {
			setIsEditing(false);
		} else {
			setIsEditing(true);
			makeInvisible();
			setInputValue(value);
		}
	};

	const handleSave = () => {
		if (validateUserArn(inputValue.trim())) {
			onSave(inputValue); // Trigger the onSave callback with the new value
			setIsEditing(false); // Exit editing mode
			makeInvisible();
			setPostMethod();
			updateUserArn(inputValue);
			setToPending(true);
			setStatus('Pending Approval');
			setToDiscard(false);
		} else {
			setMessage('Invalid User ARN Format');
			makeVisible();
		}
	};

	const handleCancel = () => {
		setInputValue(value); // Reset input value to the original value
		setIsEditing(false); // Exit editing mode
		makeInvisible();
	};

	return (
		<>
			{isEditing ? (
				<div className="input-div">
					<Input
						data-testid="user-arn-input-element"
						onChange={({ detail }) => setInputValue(detail.value)}
						value={inputValue}
					/>

					<button
						data-testid="save-icon"
						className="inline-button"
						onClick={handleSave}
					>
						<Icon name="check" />
					</button>
					<button
						data-testid="cancel-icon"
						className="inline-button"
						onClick={handleCancel}
					>
						<Icon name="close" />
					</button>
				</div>
			) : (
				<div className="input-div">
					<span
						data-testid="user-arn-span-element"
						tabIndex={0}
						onClick={handleEdit}
						className="inline-span"
						style={{
							color: isDefaultValue ? '#888' : 'inherit',
						}}
						role="button"
						aria-pressed="false"
					>
						{value || 'arn:aws:iam::123456789012:role/Admin'}
					</span>
					{arnStatus !== 'Pending Approval' &&
						arnStatus !== 'Approving' &&
						arnStatus !== 'Discarding' && (
							<button
								data-testid="user-arn-edit-button"
								className="inline-button"
								onClick={handleEdit}
							>
								<Icon name="edit" />
							</button>
						)}
				</div>
			)}
		</>
	);
};

export default InlineEdit;
