import React from 'react';
import { useIntl } from 'react-intl';
import { Switch } from 'react-router-dom';

import { Path } from '../../constants/path';
import RouteWithErrorBoundary from './RouteWithErrorBoundary';
import message from './Routes.messages';
import NotFoundPage from '../../pages/NotFoundPage';
import AppContent from '../AppContent';

const Routes = () => {
	const { formatMessage } = useIntl();

	const routes = [
		{
			exact: true,
			path: Path.Home,
			children: <AppContent />,
		},
		// Default 404 page
		{
			exact: undefined,
			path: undefined,
			children: (
				<NotFoundPage
					children={formatMessage(message.errorPage404ErrorMessage)}
				/>
			),
		},
	];

	return (
		<Switch>
			{routes.map(({ exact, path, children }, index) => (
				<RouteWithErrorBoundary
					key={index}
					exact={exact}
					path={path}
					children={children}
				/>
			))}
		</Switch>
	);
};

export default Routes;
