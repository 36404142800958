import { name as unauthorizedErrorName } from '../errors/UnauthorizedAdminAccessError';

export const MAX_WIDTH = 1200;
export const ERROR_STRING = {
	NOT_FOUND: '404 Page Not Found',
	NOT_AUTHENTICATED: 'The user is not authenticated',
	GENERIC_HEADER: 'Something went wrong',
	GENERIC_MESSAGE: 'There was an error',
	RELOAD_MESSAGE: 'Please reload the page',
};

const ERRORS = new Map();
ERRORS.set(unauthorizedErrorName, {
	headerText: "You don't have access",
	descriptionText:
		'Contact your learning administrator to learn more or change your permissions. If you are a current Learning Admin interested in using the Integrated Reporting APIs service, contact your AWS account team to learn more',
	buttonText: 'Return to Skill Builder',
	buttonUrl: (settings: any) => settings?.docebo?.homeUrl,
});

const UNSPECIFIED_ERROR = {
	headerText: ERROR_STRING.GENERIC_HEADER,
	descriptionText: ERROR_STRING.GENERIC_MESSAGE,
	buttonText: ERROR_STRING.RELOAD_MESSAGE,
	buttonUrl: (settings: any) =>
		settings?.skillbuilderReports?.administratorDasboard,
};

export const errorCopy = (errorName: string) => {
	if (ERRORS.has(errorName)) {
		return ERRORS.get(errorName);
	}
	return UNSPECIFIED_ERROR;
};
