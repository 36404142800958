import React from 'react';
import ReactDOM from 'react-dom';

import App from '../components/App/App';
import { ErrorPageWithFullLayout } from '../pages/ErrorPage';
import ErrorBoundary from '../components/ErrorBoundary';
import AppRootContextProviders from '../contexts/AppRootContextProviders';
import { getLogger } from './logger';
import { DimensionValue } from '../interfaces/logger';

export const wrap =
	(ChildComponent: (ChildComponentProps: any) => JSX.Element) =>
	(ChildComponentProps: any) =>
		(
			<React.StrictMode>
				<AppRootContextProviders>
					<ErrorBoundary isOutsideRouter={true}>
						<ChildComponent {...ChildComponentProps} />
					</ErrorBoundary>
				</AppRootContextProviders>
			</React.StrictMode>
		);

export const logRootLevelError = (error: Error) => {
	const logger = getLogger();
	logger.error('Error captured at root level', error);
	logger.counterMetric({
		metricName: 'RootError',
		dimensionValue: DimensionValue.Error,
	});
};

export const renderApplication = (element: HTMLElement) => {
	const WrappedApp = wrap(App);
	ReactDOM.render(<WrappedApp />, element);
};

export const renderErrorPage = (element: HTMLElement) => {
	const WrappedErrorPageWithFullLayout = wrap(ErrorPageWithFullLayout);
	ReactDOM.render(<WrappedErrorPageWithFullLayout />, element);
};

export const renderLastResortErrorPage = (element: HTMLElement) => {
	// Create a container for the error message
	const containerDiv = document.createElement('div');
	containerDiv.style.maxWidth = '80rem';
	containerDiv.style.margin = '2rem auto 0 auto';
	containerDiv.style.padding = '2rem';

	// Add heading
	const heading = document.createElement('h1');
	heading.textContent = 'Uh oh, something went wrong.';
	containerDiv.appendChild(heading);

	// Add paragraph and link to ask the user to reload the page
	const paragraph = document.createElement('p');
	const reloadLink = document.createElement('a');
	reloadLink.href = '/';
	reloadLink.textContent = 'Please reload the page and try again.';
	paragraph.appendChild(reloadLink);
	containerDiv.appendChild(paragraph);

	// Add link to Amazon dogs
	const amazonLink = document.createElement('a');
	amazonLink.href = 'https://www.amazon.com/dogsofamazon';
	amazonLink.target = '_blank';
	amazonLink.rel = 'noopener noreferrer';
	amazonLink.textContent = 'Meet the dogs of Amazon.';

	// Add image
	const image = document.createElement('img');
	image.style.maxWidth = '100%';
	image.alt = 'Meet the dogs of Amazon.';
	image.src = 'waffles.jpg';
	amazonLink.appendChild(image);

	containerDiv.appendChild(amazonLink);

	// Append the container div to the passed element
	element.appendChild(containerDiv);

	// Append the element to the body
	document.body.appendChild(element);
};
