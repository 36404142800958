import { QueryClient, FetchQueryOptions } from 'react-query';

import { LARSIRSettings } from '../interfaces/larsSettings';
import { SETTINGS_QUERY_KEY } from '../constants/queryKey';
import { getSettings } from './fetchSettings';

const queryClient = new QueryClient();

export const querySettings = (
	options: FetchQueryOptions<LARSIRSettings, Error> = {
		staleTime: Infinity,
	},
) => {
	const response = queryClient.fetchQuery<LARSIRSettings, Error>(
		SETTINGS_QUERY_KEY,
		getSettings,
		options,
	);
	return response;
};

export default queryClient;
