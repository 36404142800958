import { HandleRequestData } from '../interfaces/handleRequest';
import { retry, RetryOptions } from './retry';
import UnauthorizedAdminAccessError from '../errors/UnauthorizedAdminAccessError';
import { BadUserRequest } from '../errors';

const handleRequest = async <T = any>(
	rawUrl: string,
	fetchOptions: RequestInit,
	retryOptions?: Partial<RetryOptions>,
): Promise<HandleRequestData<T>> => {
	let status = 0;
	let result = null;

	const url = new URL(rawUrl, window.location.origin);
	const currentSearchParams = new URLSearchParams(window.location.search);
	for (let [key, val] of currentSearchParams.entries()) {
		url.searchParams.append(key, val);
	}
	const response = await retry(() => fetch(url, fetchOptions), retryOptions);
	status = response.status;
	result = await response.json();
	// catch non-network errors like 403 custom errors from the APIs
	if (response.status === 403) {
		throw new UnauthorizedAdminAccessError(
			JSON.stringify(result),
			status,
			response,
		);
	}
	if (response.status === 400) {
		throw new BadUserRequest(JSON.stringify(result), status, response);
	}
	if (!response.ok) {
		throw new Error(JSON.stringify(result));
	}

	return {
		status,
		result,
	};
};
export default handleRequest;
