import handleIRApiRequest from '../utils/handleIRApiRequest';

import { getLogger } from '../utils/logger';
import { DimensionValue } from '../interfaces/logger';
import { HandleRequestData } from '../interfaces/handleRequest';

export const PATH = 'OnboardCustomer';

interface FetchOptions {
	method: string;
	body?: string;
}

interface Payload {
	newPrincipalArn: string;
	existingPrincipalArn: string;
	isDiscarded?: boolean;
	isPending?: boolean;
}

const getStatus = (
	method: string,
	newUserArn: string,
	currentUserArn: string,
	isPending: boolean,
	isDiscarded: boolean,
): Promise<HandleRequestData<any>> => {
	const startTime = new Date().getTime();
	let fetchOptions: FetchOptions = {
		method: method,
	};

	if (method === 'POST') {
		const bodyData: Payload = {
			newPrincipalArn: newUserArn,
			existingPrincipalArn: currentUserArn,
		};
		if (isDiscarded) {
			bodyData.isDiscarded = isDiscarded;
		} else if (isPending) {
			bodyData.isPending = isPending;
		}
		fetchOptions = {
			method: method,
			body: JSON.stringify(bodyData),
		};
	}

	return handleIRApiRequest<any>(PATH, fetchOptions, {
		onSuccess: () => {
			const endTime = new Date().getTime();
			getLogger().timerMetric({
				metricName: `IRApi${method}Latency`,
				metricValue: endTime - startTime,
				dimensionValue: DimensionValue.Latency,
			});
		},
	});
};

const IRApi = {
	getStatus,
};
export default IRApi;
