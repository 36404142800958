import { LARSIRSettings } from '../interfaces/larsSettings';
import handleRequest from '../utils/handleRequest';

/**
 * Retrieve configuration from settings.json
 * @throws {Error} Failed to fetch settings.json
 */
export const getSettings = async (): Promise<LARSIRSettings> => {
	try {
		const response = await handleRequest<LARSIRSettings>('/settings.json', {
			method: 'GET',
		});
		return response.result;
	} catch (error) {
		throw new Error('Failed to fetch settings.json');
	}
};
