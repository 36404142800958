// the Locale type requires a key per value in this array
// Reference: https://stackoverflow.com/questions/44497388/typescript-array-to-string-literal-type
export const SUPPORTED_LOCALES = ['en-US'] as const;
export type Locale = (typeof SUPPORTED_LOCALES)[number];

const translations: Record<Locale, any> = {
	'en-US': {}, // We set default messages in English for all localized strings, so empty object is safe here
};

export default translations;
