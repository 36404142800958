import { Box, StatusIndicator } from '@amzn/awsui-components-react';
import Footer from '../Footer';
import LARSHeader from '../Header';
import { useAuthContext } from '../../contexts/AuthContextProvider';
import { Auth } from 'aws-amplify';
import { useIdleTimer } from 'react-idle-timer';
import ErrorPage from '../../pages/ErrorPage';
import Routes from '../Routes/Routes';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.scss';

const App = () => {
	const { authState } = useAuthContext();
	const { authError, authUser } = authState;
	let appContent;

	const onAction = () => {
		Auth.currentSession();
	};

	const onIdle = () => {
		Auth.signOut();
	};

	useIdleTimer({
		onIdle,
		onAction,
		timeout: 1000 * 60 * 75, // 75 mins
	});

	if (authError) {
		appContent = <ErrorPage />;
	} else if (!authUser) {
		appContent = (
			<Box textAlign="center">
				<StatusIndicator type="loading">{'Loading'}</StatusIndicator>
			</Box>
		);
	} else {
		appContent = <Routes />;
	}
	return (
		<Router>
			<div className="awsui" data-testid="App">
				<LARSHeader />

				<div id="js-main-content" tabIndex={-1}>
					{appContent}
				</div>

				<Footer />
			</div>
		</Router>
	);
};
export default App;
