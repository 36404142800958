export enum FetchStatus {
	Unspecified,
	Loading = 'LOADING',
	Loaded = 'LOADED',
	ExpectedError = 'EXPECTED_ERROR',
	UnexpectedError = 'UNEXPECTED_ERROR',
	Approving = 'APPROVING',
	Discarding = 'DISCARDING',
	PendingApproval = 'PENDING_APPROVAL',
	BadRequest = 'BADREQUEST',
}
