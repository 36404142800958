export const name = 'UnauthorizedAdminAccessError';
class UnauthorizedAdminAccessError extends Error {
	status: Number;
	response: Response;
	constructor(message: string, status: Number, response: Response) {
		super();
		this.name = name;
		this.message = message;
		this.status = status;
		this.response = response;
		// Maintains proper stack trace for where our error was thrown (only available on V8)
		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, UnauthorizedAdminAccessError);
		}
	}
}

export default UnauthorizedAdminAccessError;
