import { getAuthUserToken } from './auth';
import { RetryOptions } from './retry';
import { querySettings } from './queryUtils';
import handleRequest from './handleRequest';
import { HandleRequestData } from '../interfaces/handleRequest';

let larsIRApiDomain: string = '';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const handleIRApiRequest = async <T = any>(
	path: string,
	fetchOptions: RequestInit,
	retryOptions?: Partial<RetryOptions>,
): Promise<HandleRequestData<any>> => {
	const token = await getAuthUserToken();
	const requestOptions: RequestInit = {
		...fetchOptions,
		headers: {
			...fetchOptions.headers,
			Authorization: `Bearer ${token}`,
		},
	};

	if (!larsIRApiDomain) {
		const settings = await querySettings();
		larsIRApiDomain = settings?.api?.domain;
	}

	return handleRequest<any>(
		`https://${larsIRApiDomain}/${path}`,
		requestOptions,
		retryOptions,
	);
};

export default handleIRApiRequest;
