import * as React from 'react';
import SideNavigation from '@amzn/awsui-components-react/polaris/side-navigation';
import useSettings from '../../hooks/useSettings';

const Navigation = () => {
	const { settings } = useSettings();
	const [activeHref, setActiveHref] = React.useState('#/AdminConfiguration');
	return (
		<SideNavigation
			activeHref={activeHref}
			header={{ href: '#/', text: 'LMS Integration Services' }}
			onFollow={(event) => {
				if (!event.detail.external) {
					event.preventDefault();
					setActiveHref(event.detail.href);
				}
			}}
			items={[
				{
					type: 'link',
					text: 'Admin Configuration',
					href: '#/AdminConfiguration',
				},
				{ type: 'divider' },
				{
					type: 'link',
					text: 'Documentation',
					href: settings?.userGuide?.homeUrl || '/',
					external: true,
				},
			]}
		/>
	);
};
export default Navigation;
