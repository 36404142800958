/* eslint-disable no-console */
import KatalLogger, {
	LoggerConfig as KatalLoggerConfig,
} from '@amzn/katal-logger';
import { Dimension, Metric, EMFMetric, Unit } from '../interfaces/logger';

let logger: Logger;

const isError = (object: any): object is Error => {
	return object instanceof Error;
};

interface VerboseConfig {
	debug: boolean;
	info: boolean;
	warn: boolean;
	error: boolean;
	fatal: boolean;
}

interface LoggerConfig extends KatalLoggerConfig {
	verbose?: Partial<VerboseConfig>;
}

class Logger extends KatalLogger {
	static readonly DEFAULT_NAMESPACE: string = 'LarsStaticWebsite';
	static readonly DEFAULT_DIMENSION_NAME: string = 'Type';
	static readonly DEFAULT_COUNTER_METRIC_VALUE: number = 1;
	static readonly DEFAULT_VERBOSE_CONFIG: VerboseConfig = {
		debug: false,
		info: false,
		warn: false,
		error: false,
		fatal: false,
	};
	private verbose: VerboseConfig;

	constructor(props: LoggerConfig) {
		super(props);
		this.verbose = { ...Logger.DEFAULT_VERBOSE_CONFIG, ...props.verbose };
	}

	private static generateEMFContext(
		metrics: Array<Metric>,
		dimensions?: Array<Dimension>,
		namespace: string = Logger.DEFAULT_NAMESPACE,
	): EMFMetric {
		const metricsMap = Object.fromEntries(
			metrics.map((metric) => [metric.name, metric.value]),
		);
		const dimensionsMap = dimensions
			? Object.fromEntries(
					dimensions.map((dimension) => [
						dimension.key,
						dimension.value,
					]),
			  )
			: {};
		const dimensionKeys = dimensions
			? dimensions.map((dimension) => dimension.key)
			: [];
		return {
			emfLog: {
				_aws: {
					Timestamp: Date.now(),
					CloudWatchMetrics: [
						{
							Namespace: namespace,
							Dimensions: [dimensionKeys],
							Metrics: metrics.map((metric) => ({
								Name: metric.name,
								Unit: metric.unit,
							})),
						},
					],
				},
				...metricsMap,
				...dimensionsMap,
			},
		};
	}

	debug(message: string, context?: Record<string, any>): Promise<void>;
	debug(
		message: string,
		error: Error,
		context?: Record<string, any>,
	): Promise<void>;
	debug(
		message: string,
		error?: Error | Record<string, any>,
		context?: Record<string, any>,
	): Promise<void> {
		if (this.verbose.debug) {
			console.debug(message, error, context);
		}
		if (isError(error)) {
			return super.debug(message, error, context);
		} else {
			return super.debug(message, error);
		}
	}

	info(message: string, context?: Record<string, any>): Promise<void>;
	info(
		message: string,
		error: Error,
		context?: Record<string, any>,
	): Promise<void>;
	info(
		message: string,
		error?: Error | Record<string, any>,
		context?: Record<string, any>,
	): Promise<void> {
		if (this.verbose.info) {
			console.log(message, error, context);
		}
		if (isError(error)) {
			return super.info(message, error, context);
		} else {
			return super.info(message, error);
		}
	}

	warn(message: string, context?: Record<string, any>): Promise<void>;
	warn(
		message: string,
		error: Error,
		context?: Record<string, any>,
	): Promise<void>;
	warn(
		message: string,
		error?: Error | Record<string, any>,
		context?: Record<string, any>,
	): Promise<void> {
		if (this.verbose.warn) {
			console.warn(message, error, context);
		}
		if (isError(error)) {
			return super.warn(message, error, context);
		} else {
			return super.warn(message, error);
		}
	}

	error(message: string, context?: Record<string, any>): Promise<void>;
	error(
		message: string,
		error: Error,
		context?: Record<string, any>,
	): Promise<void>;
	error(
		message: string,
		error?: Error | Record<string, any>,
		context?: Record<string, any>,
	): Promise<void> {
		if (this.verbose.error) {
			console.error(message, error, context);
		}
		if (isError(error)) {
			return super.error(message, error, context);
		} else {
			return super.error(message, error);
		}
	}

	fatal(message: string, context?: Record<string, any>): Promise<void>;
	fatal(
		message: string,
		error: Error,
		context?: Record<string, any>,
	): Promise<void>;
	fatal(
		message: string,
		error?: Error | Record<string, any>,
		context?: Record<string, any>,
	): Promise<void> {
		if (this.verbose.fatal) {
			console.error(message, error, context);
		}
		if (isError(error)) {
			return super.fatal(message, error, context);
		} else {
			return super.fatal(message, error);
		}
	}

	metric(metrics: Metric[], dimensions?: Dimension[], namespace?: string) {
		return this.info(
			'metric',
			Logger.generateEMFContext(metrics, dimensions, namespace),
		);
	}

	counterMetric({
		metricName,
		metricValue,
		dimensionKey,
		dimensionValue,
	}: {
		metricName: string;
		metricValue?: number;
		dimensionKey?: string;
		dimensionValue: string;
	}) {
		return this.metric(
			[
				{
					name: metricName,
					value: metricValue || Logger.DEFAULT_COUNTER_METRIC_VALUE,
					unit: Unit.Count,
				},
			],
			[
				{
					key: dimensionKey || Logger.DEFAULT_DIMENSION_NAME,
					value: dimensionValue,
				},
			],
		);
	}

	timerMetric({
		metricName,
		metricValue,
		dimensionKey,
		dimensionValue,
	}: {
		metricName: string;
		metricValue: number;
		dimensionKey?: string;
		dimensionValue: string;
	}) {
		return this.metric(
			[
				{
					name: metricName,
					value: metricValue,
					unit: Unit.Milliseconds,
				},
			],
			[
				{
					key: dimensionKey || Logger.DEFAULT_DIMENSION_NAME,
					value: dimensionValue,
				},
			],
		);
	}
}

export const createLogger = ({
	loggingApiDomain,
	host,
}: {
	loggingApiDomain: string;
	host: string;
}): Logger => {
	logger = new Logger({
		url: `https://${loggingApiDomain}/v1/log`,
		logToConsole: false,
		recordMetrics: false,
		verbose: {
			info: false,
			error: true,
			fatal: true,
		},
		headers: {
			Origin: `https://${host}`,
		},
	});

	return logger;
};

/* eslint-disable no-console */
const fallbackLogger = {
	debug: console.debug,
	info: console.log,
	warn: console.warn,
	error: console.error,
	fatal: console.error,
	metric: console.log,
	counterMetric: console.log,
	timerMetric: console.log,
};

export const getLogger = (): Logger => logger || fallbackLogger;
