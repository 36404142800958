import { useState, useEffect } from 'react';
import { useNotificationContext } from '../contexts/NotificationContextProvider';
import { useIRUserArnsOnboardingContext } from '../contexts/IRUserArnsOnboardingContextProvider';
import { FetchStatus } from '../constants/fetchStatus';

type Type = 'info' | 'loading' | 'success' | 'error';

interface statusConfig {
	[key: string]: Type | undefined;
}

const statusMap: statusConfig = {
	None: 'info',
	Approving: 'loading',
	Discarding: 'loading',
	Active: 'success',
	Failed: 'error',
	'Pending Approval': 'loading',
};

const useAppContent = () => {
	const [pendingArn, setPendingArn] = useState('');
	const [statusIndicator, setStatusIndicator] = useState<statusConfig>({
		type: 'info',
	});
	const [loading, setLoading] = useState(false);
	const [expectedErrorMessage, setErrorMessage] = useState('');
	const [unExpectedError, setunExpectedError] = useState(false);
	const {
		makeInvisible,
		makeVisible,
		setMessage,
		visible,
		arnStatus,
		setStatus,
	} = useNotificationContext();
	const {
		method,
		userArns,
		status,
		error,
		setUserVibeId,
		isPending,
		isDiscarded,
		setCurrentArn,
		currentArn,
	} = useIRUserArnsOnboardingContext();
	useEffect(() => {
		if (status === FetchStatus.Approving) {
			setStatus('Approving');
			makeInvisible();
			setLoading(false);
		} else if (status === FetchStatus.Discarding) {
			setStatus('Discarding');
			makeInvisible();
			setLoading(false);
		} else if (status === FetchStatus.PendingApproval) {
			setStatus('Pending Approval');
			makeInvisible();
			setLoading(false);
		} else if (status === FetchStatus.ExpectedError && error) {
			setErrorMessage(error.name);
			setLoading(false);
		} else if (status === FetchStatus.UnexpectedError && method === 'GET') {
			setunExpectedError(true);
			setLoading(false);
		} else if (
			(status === FetchStatus.UnexpectedError && method === 'POST') ||
			status === FetchStatus.BadRequest
		) {
			const message =
				error?.message || 'System Encountered an Unexpected Error';
			if (method === 'POST') setStatus('Failed');
			makeVisible();
			setMessage(message);
			setLoading(false);
		} else if (status === FetchStatus.Loading) {
			setLoading(true);
		} else if (status === FetchStatus.Loaded) {
			if (userArns && userArns !== '[]') {
				const dynamoResponse = JSON.parse(userArns);
				const userArnData = dynamoResponse.userArnData;
				const pendingUserArnData = dynamoResponse.pendingUserArnData;
				if (userArnData && userArnData.length === 1) {
					const arns = `arn:aws:iam::${userArnData[0].account.S}:role/${userArnData[0].rolename.S}`;
					setCurrentArn(arns);
					setStatus('Active');
				}
				let pendingArnValue = '';
				if (pendingUserArnData && pendingUserArnData.length === 1) {
					const parsedPendingData = pendingUserArnData;
					pendingArnValue = parsedPendingData[0].new_arn.S;
					setUserVibeId(parsedPendingData[0].vibe_id.S);
					setPendingArn(pendingArnValue);
					setStatus('Pending Approval');
				}
			} else if (!userArns) {
				if (isDiscarded) {
					currentArn ? setStatus('Active') : setStatus('None');
				} else if (!isPending) {
					setStatus('Active');
				}
				setPendingArn('');
			}

			setLoading(false);
		}
		setStatusIndicator({
			type: statusMap[arnStatus],
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status, userArns, error, method, arnStatus]);
	return {
		currentArn,
		statusIndicator,
		loading,
		expectedErrorMessage,
		unExpectedError,
		visible,
		pendingArn,
	};
};
export default useAppContent;
