/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, Grid, Input } from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import { useNotificationContext } from '../../contexts/NotificationContextProvider';
import { useIRUserArnsOnboardingContext } from '../../contexts/IRUserArnsOnboardingContextProvider';

type ApprovalActionProps = {
	value: string;
	onSave: (newValue: string) => void; // Callback to save the new value
};

const ApprovalActionBox = ({ value, onSave }: ApprovalActionProps) => {
	const [inputValue, setInputValue] = useState(value);
	const { setStatus } = useNotificationContext();
	const { setPostMethod, updateUserArn, setToDiscard, setToPending } =
		useIRUserArnsOnboardingContext();

	const handleApprove = () => {
		setPostMethod();
		updateUserArn(inputValue);
		setStatus('Approving');
		onSave(inputValue);
	};

	const handleDecline = () => {
		setPostMethod();
		setToDiscard(true);
		setToPending(false);
		setStatus('Discarding');
		onSave('');
	};

	return (
		<>
			<Grid gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}>
				<div>
					<Input
						data-testid="new-user-arn-input-element"
						onChange={({ detail }) => setInputValue(detail.value)}
						value={value}
						readOnly
					/>{' '}
				</div>
			</Grid>
			<Grid
				gridDefinition={[
					{ colspan: 1.5 },
					{ colspan: 1.5 },
					{ colspan: 9 },
				]}
			>
				<div>
					<Button
						data-testid="approve-button"
						iconName="check"
						onClick={handleApprove}
					>
						Approve
					</Button>
				</div>
				<div>
					<Button
						data-testid="decline-button"
						iconName="close"
						onClick={handleDecline}
					>
						Decline
					</Button>
				</div>
			</Grid>
		</>
	);
};

export default ApprovalActionBox;
