import {
	AppLayout,
	Box,
	BreadcrumbGroup,
	Button,
	ColumnLayout,
	Container,
	ContentLayout,
	Header,
	Link,
	SpaceBetween,
	StatusIndicator,
	Flashbar,
} from '@amzn/awsui-components-react';
import Navigation from '../SideNavigation/SideNavigation';
import LARSHelpPanel from '../HelpPanel';
import InlineEdit from '../InlineEdit';
import React, { ReactNode, useEffect, useState } from 'react';
import { useNotificationContext } from '../../contexts/NotificationContextProvider';
import useSettings from '../../hooks/useSettings';
import ErrorMessage from '../../pages/ErrorPage/Common/ErrorMessage';
import useAppContent from '../../utils/appContentUtils';
import ApprovalActionBox from '../ApprovalActionBox';
import { useIRUserArnsOnboardingContext } from '../../contexts/IRUserArnsOnboardingContextProvider';
import { getAuthUserToken } from '../../utils/auth';
import jwtDecode from 'jwt-decode';

interface ValueWithLabelProps {
	label: string;
	children: ReactNode;
}

const ValueWithLabel = ({ label, children }: ValueWithLabelProps) => (
	<div>
		<Box variant="awsui-key-label">{label}</Box>
		<div>{children}</div>
	</div>
);

const AppContent = () => {
	const [helpOpen, setHelpOpen] = useState(false);
	const [navigationOpen, setNavigationOpen] = useState(false);
	const [value, setValue] = useState('');
	const [isApprover, setApprover] = useState(false);
	const {
		currentArn,
		statusIndicator,
		loading,
		expectedErrorMessage,
		visible,
		pendingArn,
		unExpectedError,
	} = useAppContent();
	const { arnStatus, errorMessage } = useNotificationContext();
	const { vibeId } = useIRUserArnsOnboardingContext();
	const handleSave = (newValue: string) => {
		setValue(newValue);
	};

	const { settings } = useSettings();

	useEffect(() => {
		const fetchVibeIdAndCompare = async () => {
			const jwtToken = await getAuthUserToken();
			if (jwtToken) {
				const decodedToken = jwtDecode<any>(jwtToken);
				const userVibeId = decodedToken['vibe_user_id'];
				if (vibeId && userVibeId !== vibeId) setApprover(true);
			}
		};
		fetchVibeIdAndCompare();
	}, [vibeId]);

	if (loading) {
		return (
			<Box textAlign="center">
				<StatusIndicator type="loading">{'Loading'}</StatusIndicator>
			</Box>
		);
	}
	if (expectedErrorMessage) {
		return <ErrorMessage errorName={expectedErrorMessage} />;
	}
	if (unExpectedError) {
		throw new Error('Encountered an Unexpected Error');
	}

	return (
		<div data-testid="App-Content">
			<AppLayout
				breadcrumbs={
					<BreadcrumbGroup
						items={[
							{ text: 'LMS Integration', href: '#' },
							{ text: 'Services', href: '#' },
						]}
					/>
				}
				navigationOpen={navigationOpen}
				navigation={<Navigation />}
				toolsOpen={helpOpen}
				notifications={
					visible && (
						<Flashbar
							items={[
								{
									type: 'error',
									header: 'Failed to update User ARN(s)',
									content: `Reason: ${errorMessage}`,
									id: 'system_error',
								},
							]}
						/>
					)
				}
				onToolsChange={({ detail: { open } }) => setHelpOpen(open)}
				onNavigationChange={({ detail: { open } }) =>
					setNavigationOpen(open)
				}
				tools={<LARSHelpPanel />}
				content={
					<div id="js-main-content" tabIndex={-1}>
						<ContentLayout
							header={
								<Header
									variant="h1"
									info={
										<Link
											data-testid="info-icon"
											href="/#"
											onFollow={() => setHelpOpen(true)}
										>
											Info
										</Link>
									}
									actions={
										<SpaceBetween
											direction="horizontal"
											size="xs"
										>
											<Button
												data-testid="admin-guide-button"
												href={
													settings?.userGuide?.homeUrl
												}
												target="_blank"
											>
												Admin Guide
											</Button>
											<Button
												data-testid="admin-dashboard-button"
												variant="primary"
												href={settings?.docebo?.homeUrl}
											>
												Return to administrator
												dashboard
											</Button>
										</SpaceBetween>
									}
									description="Use the fields in this page to create and manage your organization’s use of our LMS Integration Services, including Integrated Reporting and LMS Integration Full"
								>
									LMS Integration Services Admin Configuration
								</Header>
							}
						>
							<Container
								header={
									<Header
										variant="h2"
										description="Integrated Reporting APIs are secured by AWS Sigv4 authentication, which means you must use an AWS Management Console account in order to access them. Once you have selected your AWS account, you will create a Role that will be used in the API authorization process. Once you have created your Role, please enter its ARN in the field below. We will onboard your ARN to our service once we get an Approval from one of the remaining Skill Builder Learning Administrators within your organization."
									>
										Integrated Reporting Onboarding
									</Header>
								}
								className="container"
							>
								<ColumnLayout columns={2} variant="text-grid">
									<div>
										<ValueWithLabel label="Amazon Resource Name (ARN)">
											{
												<InlineEdit
													value={
														value ||
														pendingArn ||
														currentArn
													}
													onSave={handleSave}
												/>
											}
										</ValueWithLabel>
									</div>
									<div>
										<ValueWithLabel label="Status">
											<StatusIndicator
												type={statusIndicator.type}
											>
												{arnStatus}
											</StatusIndicator>
										</ValueWithLabel>
									</div>
								</ColumnLayout>
							</Container>

							{pendingArn && isApprover ? (
								<Container
									header={
										<Header
											variant="h2"
											description="A new ARN onboarding request has been made by one of the Skill Builder Learning Administrators within your organization. You can either Approve or Decline this request. Please note that approving the current request will replace your curret onboarded ARN with the new ARN."
										>
											Arn Onboarding Request
										</Header>
									}
								>
									<div>
										<ValueWithLabel label="Requested ARN to Onboard">
											{
												<ApprovalActionBox
													value={pendingArn}
													onSave={handleSave}
												/>
											}
										</ValueWithLabel>
									</div>
								</Container>
							) : (
								[]
							)}
						</ContentLayout>
					</div>
				}
			/>
		</div>
	);
};
export default AppContent;
