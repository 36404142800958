import React from 'react';
import { QueryClientProvider } from 'react-query';
import queryClient from '../utils/queryUtils';
import AuthContextProvider from './AuthContextProvider';
import NotificationContextProvider from './NotificationContextProvider';
import translations from '../i18n';
import { IntlProvider } from 'react-intl';
import { getLogger } from '../utils/logger';
import { DimensionValue } from '../interfaces/logger';
import IRUserArnsOnboardingContextProvider from './IRUserArnsOnboardingContextProvider';
const defaultLocale = 'en-US';

const RootContextProviders = ({ children }: { children: React.ReactNode }) => {
	const logger = getLogger();
	return (
		<QueryClientProvider client={queryClient}>
			<AuthContextProvider>
				<NotificationContextProvider>
					<IRUserArnsOnboardingContextProvider>
						<IntlProvider
							defaultLocale={defaultLocale}
							locale={defaultLocale}
							messages={translations[defaultLocale]}
							wrapRichTextChunksInFragment={true} //https://github.com/formatjs/formatjs/issues/1549
							onError={(error) => {
								logger.error(
									'Error captured in intl context provider',
									error,
								);
								logger.counterMetric({
									metricName: 'IntlProviderError',
									dimensionValue: DimensionValue.Error,
								});
							}}
						>
							{children}
						</IntlProvider>
					</IRUserArnsOnboardingContextProvider>
				</NotificationContextProvider>
			</AuthContextProvider>
		</QueryClientProvider>
	);
};

export default RootContextProviders;
