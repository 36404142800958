import React, {
	createContext,
	ReactNode,
	useCallback,
	useContext,
	useState,
} from 'react';

export interface NotificationContextProps {
	visible: boolean;
	arnStatus: string;
	errorMessage: string;
	makeVisible: () => void;
	makeInvisible: () => void;
	setMessage: (message: string) => void;
	setStatus: (message: string) => void;
}

export const NotificationContext =
	createContext<NotificationContextProps | null>(null);

export const useNotificationContext = () => {
	const context = useContext(NotificationContext);
	if (context === null) {
		throw new Error(
			'useNotificationContext must be used within a NotificationContextProvider tag',
		);
	}

	return context;
};

interface NotificationContextProviderProps {
	children: ReactNode;
}

const NotificationContextProvider = ({
	children,
}: NotificationContextProviderProps) => {
	const [visible, setVisible] = useState(false);
	const [errorMessage, setMessage] = useState('');
	const [arnStatus, setStatus] = useState('None');

	const makeVisible = useCallback(() => {
		setVisible(true);
	}, [setVisible]);

	const makeInvisible = useCallback(() => {
		setVisible(false);
	}, [setVisible]);

	return (
		<NotificationContext.Provider
			value={{
				arnStatus,
				visible,
				errorMessage,
				makeVisible,
				makeInvisible,
				setMessage,
				setStatus,
			}}
		>
			{children}
		</NotificationContext.Provider>
	);
};
export default NotificationContextProvider;
