import React from 'react';
import { TopNavigation } from '@amzn/awsui-components-react';
import useSettings from '../../hooks/useSettings';

const LARSHeader = () => {
	const { settings } = useSettings();
	return (
		<TopNavigation
			identity={{
				href: settings?.docebo?.homeUrl || '#',
				logo: {
					src: '/aws-tc-logo.svg',
					alt: 'aws training and certification',
				},
			}}
		/>
	);
};

export default LARSHeader;
