import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

import ErrorBoundary from '../ErrorBoundary';

const RouteWithErrorBoundary: React.FC<RouteProps> = (props: RouteProps) => {
	return (
		<ErrorBoundary isOutsideRouter={false} key={props.location?.pathname}>
			<Route {...props} />
		</ErrorBoundary>
	);
};

export default RouteWithErrorBoundary;
