import * as React from 'react';
import HelpPanel from '@amzn/awsui-components-react/polaris/help-panel';
import Icon from '@amzn/awsui-components-react/polaris/icon';
import useSettings from '../../hooks/useSettings';

const LARSHelpPanel = () => {
	const { settings } = useSettings();

	return (
		<HelpPanel
			footer={
				<div>
					<br></br>
				</div>
			}
			header={<h2>LMS Integration Services Guide</h2>}
		>
			<div>
				<p>
					Our Integrated Reporting API service provides access to your
					organization’s learning activity data across our AWS Skill
					Builder digital and classroom-based training offerings
					through secure APIs, giving you insights into your learners’
					engagement with our content and enabling you to measure the
					return on your organization’s training investments with AWS
				</p>
				<h4>Integrated Reporting Onboarding Guide</h4>
				<p>
					Integrated Reporting APIs are secured by AWS Sigv4
					authentication, which means you must use an AWS Management
					Console account in order to access them. Once you have
					selected your AWS account, you will create a Role that will
					be used in the API authorization process. For help creating
					an AWS Role, see <b>Step 1</b> in our{' '}
					<a
						data-testid="user-guide-url"
						href={settings?.userGuide?.homeUrl}
						target="_blank"
						rel="noreferrer"
					>
						LMS Integrated Reporting user guide
						<Icon name="external" />
					</a>
				</p>
				<p>
					Once you have created your Role, please enter its ARN in the
					field below and select Save. Your onboarding request will
					move to Pending Approval status, and you will need to have a
					second Learning Administrator user within your organization
					login and approve the request. By completing this approval
					step, your organization acknowledges the access this ARN
					will have to user and learning activity data available
					through our APIs. For questions or support in completing
					this step, or assigning Learning Administrator permissions
					to another user in your organization, please reach out to us
					at{' '}
					<a
						data-testid="contact-email"
						style={{ textDecoration: 'none' }}
						href="mailto:aws-lms-integrated-reporting-beta@amazon.com"
					>
						aws-lms-integrated-reporting-beta@amazon.com.
					</a>
				</p>
				<h5>Understanding User Input Controls</h5>
				<dl>
					<dt>Amazon Resource Name (ARN)</dt>
					<dd>
						This is an inline-editable field. Users can click on the
						arn text or the edit icon adjacent to the text inorder
						to enable editing. Once the appropriate arn is entered
						into the input field, users can either click the Check
						Icon to sumbit the arn or Close Icon inorder to cancel
						and come back from editing mode.
					</dd>
					<dt>Status</dt>
					<dd>
						Status icon next to the ARN input field that shows the
						following values based on the ARN Onboarding status:
						<ul>
							<li>
								<b>Pending Approval</b>
							</li>
							<li>
								<b>Approving</b>
							</li>
							<li>
								<b>Discarding</b>
							</li>
							<li>
								<b>Active</b>
							</li>
							<li>
								<b>Failed</b>
							</li>
						</ul>
					</dd>
				</dl>
			</div>
		</HelpPanel>
	);
};
export default LARSHelpPanel;
